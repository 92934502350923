import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import VueApexCharts from 'vue-apexcharts'
import money from 'v-money'

import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
//import VueMask from 'v-mask'
import VueTheMask from 'vue-the-mask'
import vco from "v-click-outside"
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies'

import 'sweetalert2/dist/sweetalert2.min.css';
import "@/assets/design/index.scss";

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

Vue.use(VueMoment, {
    moment,
})

Vue.use(money, {precision: 2})

import tinymce from 'vue-tinymce-editor'
Vue.component('tinymce', tinymce)

Vue.use(VueCookies)
Vue.$cookies.config('30d')

Vue.use(vco)
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
//Vue.use(VueMask)
Vue.use(VueSweetalert2);


import VueQuillEditor from 'vue-quill-editor'
 
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
 
Vue.use(VueQuillEditor, /* { default global options } */)

Vue.config.productionTip = false

Vue.use(require('vue-chartist'))
Vue.component('apexchart', VueApexCharts)

window._Vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')