export default {
    allMenu:{ method: 'get', url: 'menu/' },
    createdMenu:{ method: 'post', url: 'menu/' },
    createdFeatured:{ method: 'post', url: 'featured/' },
    allFeatured:{ method: 'get', url: 'featured/' },
    updateFeatured:{ method: 'put', url: 'featured/' },
    deleteFeatured:{ method: 'delete', url: 'featured{/id}' },
    deleteMenu:{ method: 'delete', url: 'menu{/id}' },
    update: { method: 'put', url: 'store/' },
    find: { method: 'get', url: 'store/' },
    updateImage: { method: 'put', url: 'image{/id}' },
    deleteImage: { method: 'delete', url: 'image{/id}' },
    createdSlider:{ method: 'post', url: 'slider/' },
    allSlider:{ method: 'get', url: 'slider/' },
    updateSlider:{ method: 'put', url: 'slider/' },
    deleteSlider:{ method: 'delete', url: 'slider{/id}' },
    findInfo:{ method: 'get', url: 'info/' },
    updateInfo: { method: 'put', url: 'info/' },
    createdCountdown:{ method: 'post', url: 'countdown/' },
    allCountdown:{ method: 'get', url: 'countdown/' },
    updateCountdown:{ method: 'put', url: 'countdown/' },
    deleteCountdown:{ method: 'delete', url: 'countdown{/id}' },
  }