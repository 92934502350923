import {services as auth} from '@/views/auth'
import {services as profile} from '@/views/profile'
import {services as category} from '@/views/category'
import {services as manufacturer} from '@/views/manufacturer'
import {services as brand} from '@/views/brand'
import {services as product} from '@/views/product'
import {services as rating} from '@/views/rating'
import {services as page} from '@/views/page'
import {services as customer} from '@/views/customer'
import {services as sale} from '@/views/sale'
import {services as email} from '@/views/email'
import {services as testimony} from '@/views/testimony'
import {services as blog} from '@/views/blog'
import {services as contact} from '@/views/contact'
import {services as newsletter} from '@/views/newsletter'
import {services as appearance} from '@/views/appearance'
import {services as setting} from '@/views/setting'
import {services as finance} from '@/views/finance'
import {services as home} from '@/views/home'

export default {
    auth,
    profile,
    category,
    manufacturer,
    brand,
    product,
    rating,
    page,
    customer,
    sale,
    email,
    testimony,
    blog,
    contact,
    newsletter,
    appearance,
    setting,
    finance,
    home
}