import { store as auth } from '@/views/auth'
import { store as profile } from '@/views/profile'
import { store as category } from '@/views/category'
import { store as manufacturer } from '@/views/manufacturer'
import { store as brand } from '@/views/brand'
import { store as product } from '@/views/product'
import { store as rating } from '@/views/rating'
import { store as page } from '@/views/page'
import { store as customer } from '@/views/customer'
import { store as sale } from '@/views/sale'
import { store as email } from '@/views/email'
import { store as testimony } from '@/views/testimony'
import { store as blog } from '@/views/blog'
import { store as contact } from '@/views/contact'
import { store as newsletter } from '@/views/newsletter'
import { store as appearance } from '@/views/appearance'
import { store as setting } from '@/views/setting'
import { store as finance } from '@/views/finance'
import { store as home } from '@/views/home'

export default {
    auth,
    profile,
    category,
    manufacturer,
    brand,
    product,
    rating,
    page,
    customer,
    sale,
    email,
    testimony,
    blog,
    contact,
    newsletter,
    appearance,
    setting,
    finance,
    home
  }