import store from '../store'

export default async (to, from, next) => {
  document.title = `${to.name} - LP10 e-commerce`
  if (to.name !== 'login' && to.name !== 'recover' && to.name !== 'recover-code' && !store.getters['auth/hasToken']) {
    try {
      await store.dispatch('auth/ActionCheckToken')
      next({ path: to.path })
    } catch (err) {
      next({ name: 'login' })
    }
  } else {
    if (to.name === 'login' && store.getters['auth/hasToken']) {
      next({ name: 'home' })
    }else{
      next()
    }
  }
}